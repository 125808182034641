import * as React from "react";
import { WebsocketContext } from "./WebsocketProvider";
import { TimerContext } from "./TimerProvider";
import LabeledPlay from "./LabeledPlay";
import PublicRoomsPane from "./PublicRoomsPane";
var JoinRoom = function (props) {
    var _a = React.useState(false), editable = _a[0], setEditable = _a[1];
    var _b = React.useState(props.room_name.length !== 16), shouldGenerate = _b[0], setShouldGenerate = _b[1];
    var send = React.useContext(WebsocketContext).send;
    var setTimeout = React.useContext(TimerContext).setTimeout;
    var handleChange = function (event) {
        return props.setName(event.target.value.trim());
    };
    var handleRoomChange = function (event) {
        return props.setRoomName(event.target.value.trim());
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        if (props.name.length > 0 && props.room_name.length === 16) {
            send({
                room_name: props.room_name,
                name: props.name,
            });
        }
    };
    var editableRoomName = (React.createElement("input", { type: "text", placeholder: "Enter a room code", value: props.room_name, onChange: handleRoomChange, maxLength: 16 }));
    var nonEditableRoomName = (React.createElement("span", { title: "Set the room name", onClick: function (evt) {
            evt.preventDefault();
            setEditable(true);
        } }, props.room_name));
    var generateRoomName = function () {
        var arr = new Uint8Array(8);
        window.crypto.getRandomValues(arr);
        setShouldGenerate(false);
        props.setRoomName(Array.from(arr, function (d) { return ("0" + d.toString(16)).substr(-2); }).join(""));
    };
    if (shouldGenerate) {
        setTimeout(generateRoomName, 0);
    }
    return (React.createElement("div", null,
        React.createElement(LabeledPlay, { cards: ["🃟", "🃟", "🃏", "🃏"], trump: { NoTrump: {} }, label: null }),
        React.createElement("form", { className: "join-room", onSubmit: handleSubmit },
            React.createElement("div", null,
                React.createElement("h2", null,
                    React.createElement("label", null,
                        React.createElement("strong", null, "Room Name:"),
                        " ",
                        editable ? editableRoomName : nonEditableRoomName,
                        " ",
                        React.createElement("span", { title: "Generate new room", onClick: function () { return generateRoomName(); } }, "\uD83C\uDFB2"),
                        " "))),
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("strong", null, "Player Name:"),
                    " ",
                    React.createElement("input", { type: "text", placeholder: "Enter your name here", value: props.name, onChange: handleChange, autoFocus: true })),
                React.createElement("input", { type: "submit", value: "Join (or create) the game!", disabled: props.room_name.length !== 16 ||
                        props.name.length === 0 ||
                        props.name.length > 32 }))),
        React.createElement("div", null,
            React.createElement("p", null, "Welcome to the game! Enter your name above to create a new game, or (re-)join the game if it already exists."),
            React.createElement("p", null,
                "If you're unfamiliar with the game, it might be helpful to",
                " ",
                React.createElement("a", { href: "rules", target: "_blank" }, "read the rules"),
                " ",
                "and then shadow another player\u2014you can just join with the same name, case-sensitive."),
            React.createElement("p", null, "Once you are in the game, share the room link with at least three friends to start playing!"),
            React.createElement("p", null, "This is a game with many house rules, so be sure to check out the game settings to see if your favorite rules are implemented. There's also a settings gear at the top, which can change how the game looks to you.")),
        React.createElement(PublicRoomsPane, { setRoomName: props.setRoomName })));
};
export default JoinRoom;
